<template>
    <v-card v-if="session && parameters">
        <v-card-text>
            <v-expansion-panels focusable multiple>
                <v-expansion-panel v-for="machine in Object.values(parameters).sort((a, b) => (a.name > b.name) ? 1 : -1)">
                    <v-expansion-panel-header>
                        <span class="d-flex align-center justify-space-between">
                            <span v-text="machine.name"></span>
                            <span>
                                <v-btn color="secondary" icon>
                                    <v-icon @click.prevent.stop="openRenameDialog(machine)">mdi-pencil-outline</v-icon>
                                </v-btn>
                                <v-btn color="primary" icon>
                                    <v-icon>mdi-cog-outline</v-icon>
                                </v-btn>
                                <v-btn color="error" icon>
                                    <v-icon @click.prevent.stop="deleteMachine(machine)">mdi-delete-outline</v-icon>
                                </v-btn>
                            </span>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="pl-5">
                            <v-col cols="12" md="6" lg="3">
                                <v-select class="mb-5" :items="icons" item-value="index" v-model="machine.icon" label="Icone">
                                    <template slot="item" slot-scope="data">
                                        <div class="py-5 d-flex align-center justify-center">
                                            <v-img contain height="150px" width="150px" :src="require(`@/assets/images/icons/machines/${data.item.file}`)"></v-img>
                                        </div>
                                    </template>
                                    <template slot="selection" slot-scope="data">
                                        <div class="py-5 d-flex align-center justify-center">
                                            <v-img contain height="150px" width="150px" :src="require(`@/assets/images/icons/machines/${data.item.file}`)"></v-img>
                                        </div>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6" lg="9">
                                <v-row class="pl-5">
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Temps de livraison" suffix="Période(s)" v-model="machine.delivery"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Nombre de postes de travail" suffix="Poste(s)" v-model="machine.workplaceAmount"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Valeur brute" suffix="€" v-model="machine.grossValue"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Frais de structure" suffix="€" v-model="machine.structuralCost"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Budget de maintenance optimal" suffix="€" v-model="machine.optimalMaintenanceBudget"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Durée d'amortissement" suffix="année(s)" v-model="machine.deprecationPeriods"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Consomation electrique" suffix="W/Production" v-model="machine.conso"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Emissions de CO2" suffix="g/Production" v-model="machine.carbonEmission"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Perte a la revente" suffix="%" v-model="machine.saleLoss"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Modificateur de production" suffix="%" v-model="machine.productionBoost"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Coûts de R&D" suffix="€" v-model="machine.rndCost"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-switch label="Disponibilité" v-model="machine.available"></v-switch>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="d-flex align-center justify-center">
                <v-col cols="8" class="pa-3 d-flex align-center justify-center">
                    <v-select :items="machines" return-object item-text="name" v-model="selectedMachine" label="Machine"></v-select>
                    <v-btn color="primary" outlined class="ml-3" @click="addMachine">
                        <v-icon>mdi-plus</v-icon>
                        <b>Ajouter la machine</b>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="machineRenameDialog" scrollable persistent max-width="500px">
            <v-card>
                <v-card-title class="headline">Renommer la machine</v-card-title>
                <v-card-text>
                    <v-text-field label="Nom de la machine" v-model="machineRenameName"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="machineRenameDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="renameMachine">Renommer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { v4 as uuidv4 } from 'uuid'

import assetsIcons from '@/config/assetsIcons';

import Machine from "@/classes/Machine";

export default {
    name: "MachinesParametersTab",
    props: ["session"],
    data() {
        return {
            unsub: [],
            parameters : this.session.machinesConfig,
            machines : [],
            selectedMachine : null,
            machineRenameDialog : false,
            machineRenameSelectedMachine : null,
            machineRenameName : null,
            icons: assetsIcons.machines,
        }
    },
    created() {
        this.unsub.push(Machine.listenAll((machines) => {
            this.machines = machines
            this.machines.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }))
    },
    methods: {
        addMachine() {
            if (this.selectedMachine) {
                let newMachine = {...this.selectedMachine}
                newMachine.uuid = uuidv4()
                this.parameters[newMachine.uuid] = newMachine
                this.selectedMachine = null
                this.$emit('shapeChange')
            }
        },
        openRenameDialog(machine) {
            this.machineRenameSelectedMachine = machine
            this.machineRenameName = machine.name
            this.machineRenameDialog = true
        },
        renameMachine() {
            this.machineRenameSelectedMachine.name = this.machineRenameName
            this.machineRenameDialog = false
        },
        deleteMachine(machine) {
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer cette machine ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    delete this.parameters[machine.uuid]
                    this.$forceUpdate()
                    this.$emit('shapeChange')
                }
            })
        }
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>