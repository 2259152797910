<template>
    <v-card v-if="session && parameters">
        <v-card-text>
            <v-expansion-panels focusable multiple>
                <v-expansion-panel v-for="formation in parameters">
                    <v-expansion-panel-header>
                        <span class="d-flex align-center justify-space-between">
                            <span v-text="formation.name"></span>
                            <span>
                                <v-btn color="secondary" icon>
                                    <v-icon @click.prevent.stop="openRenameDialog(formation)">mdi-pencil-outline</v-icon>
                                </v-btn>
                                <v-btn color="primary" icon>
                                    <v-icon>mdi-cog-outline</v-icon>
                                </v-btn>
                                <v-btn color="error" icon>
                                    <v-icon @click.prevent.stop="deleteFormation(formation)">mdi-delete-outline</v-icon>
                                </v-btn>
                            </span>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="pl-5">
                            <v-col cols="12">
                                <v-select :items="jobItems" v-model="formation.job" label="Poste"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field type="number" label="Cout" suffix="€" v-model="formation.cost"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field type="number" label="Durée" suffix="jour(s)" v-model="formation.duration"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field type="number" label="Augmentation d'indice social" suffix="%" v-model="formation.salaryIncrease"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field type="number" label="Augmentation d'efficacité" suffix="%" v-model="formation.efficiencyIncrease"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="formation.job != 'Salesman'">
                                <v-text-field type="number" label="Augmentation de capacité" :suffix="formation.job == 'ProductManager' ? 'Ouvrier(s)' : 'Employé(s)'" v-model="formation.staffCapacityIncrease"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="d-flex align-center justify-center">
                <v-col cols="8" class="pa-3 d-flex align-center justify-center">
                    <v-select :items="formations" return-object item-text="name" v-model="selectedFormation" label="Formation"></v-select>
                    <v-btn color="primary" outlined class="ml-3" @click="addFormation">
                        <v-icon>mdi-plus</v-icon>
                        <b>Ajouter le formation</b>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="formationRenameDialog" scrollable persistent max-width="500px">
            <v-card>
                <v-card-title class="headline">Renommer le formation</v-card-title>
                <v-card-text>
                    <v-text-field label="Nom de la formation" v-model="formationRenameName"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="formationRenameDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="renameFormation">Renommer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { v4 as uuidv4 } from 'uuid'

import Formation from "@/classes/Formation";

export default {
    name: "FormationsParametersTab",
    props: ["session"],
    data() {
        return {
            unsub: [],
            parameters : this.session.formationsConfig,
            jobItems: [
                {
                    text: 'Vendeur',
                    value: 'Salesman',
                },
                {
                    text: 'Manager',
                    value: 'ServiceManager',
                },
                {
                    text: 'Chef de production',
                    value: 'ProductManager',
                },
            ],
            formations : [],
            selectedFormation : null,
            formationRenameDialog : false,
            formationRenameSelected : null,
            formationRenameName : null
        }
    },
    created() {
        this.unsub.push(Formation.listenAll((formations) => {
            this.formations = formations
            this.formations.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }))
    },
    methods: {
        addFormation() {
            if (this.selectedFormation) {
                let newFormation = {...this.selectedFormation}
                newFormation.uuid = uuidv4()
                this.parameters[newFormation.uuid] = newFormation
                this.selectedFormation = null
            }
        },
        openRenameDialog(formation) {
            this.formationRenameSelected = formation
            this.formationRenameName = formation.name
            this.formationRenameDialog = true
        },
        renameFormation() {
            this.formationRenameSelected.name = this.formationRenameName
            this.formationRenameDialog = false
        },
        deleteFormation(formation) {
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer cette formation ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    delete this.parameters[formation.uuid]
                    this.$forceUpdate()
                }
            })
        }
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>