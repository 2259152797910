<template>
    <v-card v-if="session && parameters">
        <v-card-text>
            <h3 class="pb-5 pt-5">1. Paramètres globaux</h3>
            <v-row class="pl-5">
                <v-col cols="12" md="6" lg="4">
                    <v-text-field type="number" label="Budget sociale minimum par salarié" suffix="€" v-model="parameters.common.minimalSocialBudget"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-text-field type="number" label="Budget sociale maximum par salarié" suffix="€" v-model="parameters.common.maximalSocialBudget"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-text-field type="number" label="Indice sociale minimum par salarié" suffix="%" v-model="parameters.common.minimalSocialIndex"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-text-field type="number" label="Indice sociale maximum par salarié" suffix="%" v-model="parameters.common.maximalSocialIndex"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-text-field type="number" label="Frais d'embauche" suffix="%" v-model="parameters.common.hiringCharges"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-text-field type="number" label="Frais de licenciement" suffix="%" v-model="parameters.common.firingCharges"></v-text-field>
                </v-col>
            </v-row>
            <v-divider class="my-5"></v-divider>
            <h3 class="pb-5 pt-5">6. Paramètres des ouvriers</h3>
            <v-row class="pl-5">
                <v-col cols="12" md="6" lg="4">
                    <v-text-field type="number" label="Salaire de base" suffix="€" v-model="parameters.worker.baseSalary"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-text-field type="number" label="Capacité de production" suffix="Heure(s)" v-model="parameters.worker.productionTime"></v-text-field>
                </v-col>
            </v-row>
            <v-divider class="my-5"></v-divider>
            <h3 class="pb-5 pt-5">7. Paramètres des employés</h3>
            <v-row class="pl-5">
                <v-col cols="12" md="6" lg="4">
                    <v-text-field type="number" label="Salaire de base" suffix="€" v-model="parameters.employee.baseSalary"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-text-field type="number" label="Capacité de production" suffix="Heure(s)" v-model="parameters.employee.productionTime"></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "HRConfigDialog",
    props: ["session"],
    data() {
        return {
            parameters : {...this.session.hrParameters}
        }
    },
}
</script>