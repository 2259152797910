<template>
    <v-card v-if="session && parameters">
        <v-card-text>
            <v-expansion-panels focusable multiple>
                <v-expansion-panel v-for="cv in parameters">
                    <v-expansion-panel-header>
                        <span class="d-flex align-center justify-space-between">
                            <span v-text="cv.lastname + ' ' + cv.firstname + ' (' + jobText[cv.job] + ')'"></span>
                            <span>
                                <!-- <v-btn color="secondary" icon>
                                    <v-icon @click.prevent.stop="openRenameDialog(cv)">mdi-pencil-outline</v-icon>
                                </v-btn> -->
                                <v-btn color="primary" icon>
                                    <v-icon>mdi-cog-outline</v-icon>
                                </v-btn>
                                <v-btn color="error" icon>
                                    <v-icon @click.prevent.stop="deleteCv(cv)">mdi-delete-outline</v-icon>
                                </v-btn>
                            </span>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="pl-5">
                            <v-col cols="12" md="6" lg="4">
                                <v-select :items="jobItems" v-model="cv.job" label="Poste"></v-select>
                            </v-col>
                            <v-col cols="12" md="6" lg="4">
                                <v-text-field type="number" label="Salaire de base" suffix="€" v-model="cv.baseSalary"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="4" v-if="cv.job == 'Salesman'">
                                <v-text-field type="number" label="Efficacité de vente" suffix="Point(s)" v-model="cv.sellingEfficiency"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="4" v-if="cv.job != 'Salesman'">
                                <v-text-field type="number" label="Boost de productivité" suffix="%" v-model="cv.productivityBoost"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="4" v-if="cv.job != 'Salesman'">
                                <v-text-field type="number" label="Capacité" :suffix="cv.job == 'ProductManager' ? 'Ouvrier(s)' : 'Employé(s)'" v-model="cv.staffCapacity"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="d-flex align-center justify-center">
                <v-col cols="8" class="pa-3 d-flex align-center justify-center">
                    <v-select :items="cvs" return-object item-text="lastname" v-model="selectedCv" label="Cv">
                        <template v-slot:item="{ item }">
                            <span v-text="item.lastname + ' ' + item.firstname + ' (' + jobText[item.job] + ')'"></span>
                        </template>
                        <template v-slot:selection="{ item }">
                            <span v-text="item.lastname + ' ' + item.firstname + ' (' + jobText[item.job] + ')'"></span>
                        </template>

                    </v-select>
                    <v-btn color="primary" outlined class="ml-3" @click="addCv">
                        <v-icon>mdi-plus</v-icon>
                        <b>Ajouter le cv</b>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="cvRenameDialog" scrollable persistent max-width="500px">
            <v-card>
                <v-card-title class="headline">Renommer le cv</v-card-title>
                <v-card-text>
                    <v-text-field label="Prenom" v-model="cvRenameFirstname"></v-text-field>
                    <v-text-field label="Nom" v-model="cvRenameLastname"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="cvRenameDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="renameCv">Renommer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { v4 as uuidv4 } from 'uuid'

import Cv from "@/classes/Cv";

export default {
    name: "CvsParametersTab",
    props: ["session"],
    data() {
        return {
            unsub: [],
            parameters : this.session.cvConfig,
            jobText : {
                "Salesman" : "Vendeur",
                "ServiceManager" : "Manager",
                "ProductManager" : "Chef de production",
            },
            jobItems: [
                {
                    text: 'Vendeur',
                    value: 'Salesman',
                },
                {
                    text: 'Manager',
                    value: 'ServiceManager',
                },
                {
                    text: 'Chef de production',
                    value: 'ProductManager',
                },
            ],
            cvs : [],
            selectedCv : null,
            cvRenameDialog : false,
            cvRenameSelectedCv : null,
            cvRenameFirstname : null,
            cvRenameLastname : null,
        }
    },
    created() {
        this.unsub.push(Cv.listenAll((cvs) => {
            this.cvs = cvs
            this.cvs.sort((a, b) => (a.lastname > b.lastname) ? 1 : -1)
        }))
    },
    methods: {
        addCv() {
            if (this.selectedCv) {
                let newCv = {...this.selectedCv}
                newCv.uuid = uuidv4()
                this.parameters[newCv.uuid] = newCv
                this.selectedCv = null
            }
        },
        openRenameDialog(cv) {
            this.cvRenameSelectedCv = cv
            this.cvRenameFirstname = cv.firstname
            this.cvRenameLastname = cv.lastname
            this.cvRenameDialog = true
        },
        renameCv() {
            this.cvRenameSelectedCv.firstname = this.cvRenameFirstname
            this.cvRenameSelectedCv.lastname = this.cvRenameLastname
            this.cvRenameDialog = false
        },
        deleteCv(cv) {
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer ce cv ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    delete this.parameters[cv.uuid]
                    this.$forceUpdate()
                }
            })
        }
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>