import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "cvs"

class Cv{
    constructor(id, firstname, lastname, job, baseSalary, sellingEfficiency, productivityBoost, staffCapacity, trainingReceived, fileId){
        this.id = id
        this.firstname = firstname
        this.lastname = lastname
        this.job = job
        this.baseSalary = baseSalary
        this.sellingEfficiency = sellingEfficiency
        this.productivityBoost = productivityBoost
        this.staffCapacity = staffCapacity
        this.trainingReceived = trainingReceived
        this.fileId = fileId
    }

    static async initializeOne(firstname, lastname){
        let tmp_cv = new Cv(null, firstname, lastname, "Salesman", 7000, 1, 0, 0, [], null)
        return tmp_cv
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let cvs = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_cv = new Cv(doument.id, data.firstname, data.lastname, data.job, data.baseSalary, data.sellingEfficiency, data.productivityBoost, data.staffCapacity, data.trainingReceived, data.fileId)
            cvs.push(tmp_cv)
        })

        return cvs
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let cv = new Cv(response.id, data.firstname, data.lastname, data.job, data.baseSalary, data.sellingEfficiency, data.productivityBoost, data.staffCapacity, data.trainingReceived, data.fileId)

        return cv
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let cvs = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_cv = new Cv(document.id, data.firstname, data.lastname, data.job, data.baseSalary, data.sellingEfficiency, data.productivityBoost, data.staffCapacity, data.trainingReceived, data.fileId)
                cvs.push(tmp_cv)
            })
            
            if(callback != null){
                callback(cvs)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let cv = new Cv(snapshot.id, data.firstname, data.lastname, data.job, data.baseSalary, data.sellingEfficiency, data.productivityBoost, data.staffCapacity, data.trainingReceived, data.fileId)
            
            if(callback != null){
                callback(cv)
            }
        })
        return unsub
    }

    async rename(newFirstname, newLastname){
        this.firstname = newFirstname
        this.lastname = newLastname
        await updateDoc(doc(db, collectionName, this.id), {
            firstname : this.firstname,
            lastname : this.lastname,
        })
    }

    async removeFile(){
        this.fileId = null
        await updateDoc(doc(db, collectionName, this.id), {
            fileId : this.fileId,
        })
    }

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                firstname : this.firstname,
                lastname : this.lastname,
                job : this.job,
                baseSalary : this.baseSalary,
                sellingEfficiency : this.sellingEfficiency,
                productivityBoost : this.productivityBoost,
                staffCapacity : this.staffCapacity,
                trainingReceived : this.trainingReceived,
                fileId : this.fileId,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                firstname : this.firstname,
                lastname : this.lastname,
                job : this.job,
                baseSalary : this.baseSalary,
                sellingEfficiency : this.sellingEfficiency,
                productivityBoost : this.productivityBoost,
                staffCapacity : this.staffCapacity,
                trainingReceived : this.trainingReceived,
                fileId : this.fileId,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Cv