import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "zones"

class Zone{
    constructor(id, name, conjuncturalIndex, residentByCompany, purchasingPower, specificCosts, overcost, transports){
        this.id = id
        this.name = name
        this.conjuncturalIndex = conjuncturalIndex
        this.residentByCompany = residentByCompany
        this.purchasingPower = purchasingPower
        this.specificCosts = specificCosts
        this.overcost = overcost
        this.transports = transports
    }

    static async initializeOne(name){
        let tmp_transports = {
          car : {
            available : true,
            cost : 1,
            carbonEmission : 5
          }, 
          train : {
            available : true,
            cost : 2,
            carbonEmission : 10
          }, 
          boat : {
            available : true,
            cost : 5,
            carbonEmission : 50
          },
          plane : {
            available : true,
            cost : 3,
            carbonEmission : 100
          }, 
        }
        let tmp_zone = new Zone(null, name, 100, 450, 4000, 0, 0, tmp_transports)
        return tmp_zone
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let zones = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_zone = new Zone(doument.id, data.name, data.conjuncturalIndex, data.residentByCompany, data.purchasingPower, data.specificCosts, data.overcost, data.transports)
            zones.push(tmp_zone)
        })

        return zones
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let zone = new Zone(response.id, data.name, data.conjuncturalIndex, data.residentByCompany, data.purchasingPower, data.specificCosts, data.overcost, data.transports)

        return zone
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let zones = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_zone = new Zone(document.id, data.name, data.conjuncturalIndex, data.residentByCompany, data.purchasingPower, data.specificCosts, data.overcost, data.transports)
                zones.push(tmp_zone)
            })
            
            if(callback != null){
                callback(zones)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let zone = new Zone(snapshot.id, data.name, data.conjuncturalIndex, data.residentByCompany, data.purchasingPower, data.specificCosts, data.overcost, data.transports)
            
            if(callback != null){
                callback(zone)
            }
        })
        return unsub
    }

    async rename(newName){
        this.name = newName
        await updateDoc(doc(db, collectionName, this.id), {
            name : newName,
        })
    }

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                name : this.name,
                conjuncturalIndex : this.conjuncturalIndex,
                residentByCompany : this.residentByCompany,
                purchasingPower : this.purchasingPower,
                specificCosts : this.specificCosts,
                overcost : this.overcost,
                transports : this.transports,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                name : this.name,
                conjuncturalIndex : this.conjuncturalIndex,
                residentByCompany : this.residentByCompany,
                purchasingPower : this.purchasingPower,
                specificCosts : this.specificCosts,
                overcost : this.overcost,
                transports : this.transports,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Zone