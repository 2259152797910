<template>
    <span v-if="session">
        <v-dialog v-model="configDialog" scrollable persistent max-width="1200px">
            <v-card>
                <v-card-title class="headline" v-text="'Configurer la session ( ' + session.name + ' )'"></v-card-title>
                <v-card-text>
                    <v-tabs v-model="tab" centered>
                        <v-tab>General</v-tab>
                        <v-tab>RH</v-tab>
                        <v-tab>Decision</v-tab>
                        <v-tab>Affichage</v-tab>
                        <v-tab>Zones</v-tab>
                        <v-tab>Produits</v-tab>
                        <v-tab>Services</v-tab>
                        <v-tab>Machines</v-tab>
                        <v-tab>Production</v-tab>
                        <v-tab>CVs</v-tab>
                        <v-tab>Formations</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <MainParametersTab :session="session"/>
                        </v-tab-item>
                        <v-tab-item>
                            <HRParametersTab :session="session"/>
                        </v-tab-item>
                        <v-tab-item>
                            <DecisionParametersTab :session="session"/>
                        </v-tab-item>
                        <v-tab-item>
                            <DisplayParametersTab :session="session"/>
                        </v-tab-item>
                        <v-tab-item>
                            <ZonesParametersTab :session="session"/>
                        </v-tab-item>
                        <v-tab-item>
                            <ProductsParametersTab :session="session" @shapeChange="updateProductionShape"/>
                        </v-tab-item>
                        <v-tab-item>
                            <ServicesParametersTab :session="session" @shapeChange="updateProductionShape"/>
                        </v-tab-item>   
                        <v-tab-item>
                            <MachinesParametersTab :session="session" @shapeChange="updateProductionShape"/>
                        </v-tab-item>
                        <v-tab-item>
                            <ProductionParametersTab :session="session" ref="productionParametersTab"/>
                        </v-tab-item>
                        <v-tab-item>
                            <CVsParametersTab :session="session"/>
                        </v-tab-item>
                        <v-tab-item>
                            <FormationsParametersTab :session="session"/>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="closeDialog">Annuler</v-btn>
                    <v-btn color="primary" text @click="save">Sauvegarder</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import MainParametersTab from "@/components/sessions/sessionConfigTabs/mainParametersTab.vue"
import HRParametersTab from "@/components/sessions/sessionConfigTabs/HRParametersTab.vue"
import DecisionParametersTab from "@/components/sessions/sessionConfigTabs/DecisionParametersTab.vue"
import DisplayParametersTab from "@/components/sessions/sessionConfigTabs/DisplayParametersTab.vue"
import ZonesParametersTab from "@/components/sessions/sessionConfigTabs/ZonesParametersTab.vue"
import ProductsParametersTab from "@/components/sessions/sessionConfigTabs/ProductsParametersTab.vue"
import ServicesParametersTab from "@/components/sessions/sessionConfigTabs/ServicesParametersTab.vue"
import MachinesParametersTab from "@/components/sessions/sessionConfigTabs/MachinesParametersTab.vue"
import CVsParametersTab from "@/components/sessions/sessionConfigTabs/CVsParametersTab.vue"
import FormationsParametersTab from "@/components/sessions/sessionConfigTabs/FormationsParametersTab.vue"
import ProductionParametersTab from '@/components/sessions/sessionConfigTabs/ProductionParametersTab.vue'

export default {
    name: "SessionConfigDialog",
    components: {
    MainParametersTab,
    HRParametersTab,
    DecisionParametersTab,
    DisplayParametersTab,
    ZonesParametersTab,
    ProductsParametersTab,
    ServicesParametersTab,
    MachinesParametersTab,
    CVsParametersTab,
    FormationsParametersTab,
    ProductionParametersTab,
},
    data() {
        return {
            configDialog: false,
            session: null,
            tab: null,
        }
    },
    methods: {
        openDialog(session) {
            this.session = session
            this.updateProductionShape()
            this.configDialog = true
        },
        closeDialog() {
            this.configDialog = false
            this.tab = null
            this.session = null
        },
        updateProductionShape() {
            if(this.session.productionParameters){
                let tmp_parameters = {...this.session.productionParameters}
                for(let machineId in this.session.machinesConfig){
                    if(tmp_parameters[machineId] == undefined){
                        tmp_parameters[machineId] = {
                            products: {},
                            services: {},
                        }
                    }
                    for(let productId in this.session.productsConfig){
                        if(tmp_parameters[machineId].products[productId] == undefined){
                            tmp_parameters[machineId].products[productId] = true
                        }
                    }
                    for(let serviceId in this.session.servicesConfig){
                        if(tmp_parameters[machineId].services[serviceId] == undefined){
                            tmp_parameters[machineId].services[serviceId] = true
                        }
                    }
                }
                for(let machineId in tmp_parameters){
                    if(!this.session.machinesConfig[machineId]){
                        delete tmp_parameters[machineId]
                    }else{
                        for(let productId in tmp_parameters[machineId].products){
                            if(!this.session.productsConfig[productId]){
                                delete tmp_parameters[machineId].products[productId]
                            }
                        }
                        for(let serviceId in tmp_parameters[machineId].services){
                            if(!this.session.servicesConfig[serviceId]){
                                delete tmp_parameters[machineId].services[serviceId]
                            }
                        }
                    }
                }
                this.session.productionParameters = tmp_parameters
                this.$forceUpdate()
                if(this.$refs.productionParametersTab){
                    this.$refs.productionParametersTab.reloadData()
                }
            }
        },
        save() {
            this.session.save()
            Swal.fire({
                title: 'Session sauvegardée',
                icon: 'success',
                confirmButtonText: 'Ok',
            })
            this.configDialog = false
            this.tab = null
            this.session = null
        },
    },
}
</script>