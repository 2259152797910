<template>
    <v-card v-if="session && parameters">
        <v-card-text>
            <h3 class="pb-5 pt-5">1. Paramètres globaux</h3>
            <v-row class="pl-5">
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Augmentation de capital" v-model="parameters.common.capitalIncreasement"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Emprunts" v-model="parameters.common.loan"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Escomptes" v-model="parameters.common.discount"></v-switch>
                </v-col>
                <!-- <v-col cols="12" md="6" lg="4">
                    <v-switch label="Dividendes" v-model="parameters.common.dividend"></v-switch>
                </v-col> -->
            </v-row>
            <v-divider class="my-5"></v-divider>
            <h3 class="pb-5 pt-5">2. Paramètres des études</h3>
            <v-row class="pl-5">
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Achat d'etude de marché" v-model="parameters.studies.purchaseMarketStudy"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Achat d'etude de ressources humaines" v-model="parameters.studies.purchaseHrStudy"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Achat d'etude d'attractivité" v-model="parameters.studies.purchaseCostStudy"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Achat d'etude RSE" v-model="parameters.studies.purchaseRseStudy"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Achat d'etude de coûts" v-model="parameters.studies.purchaseAttractivityStudy"></v-switch>
                </v-col>
            </v-row>
            <v-divider class="my-5"></v-divider>
            <h3 class="pb-5 pt-5">3. Paramètres des budgets</h3>
            <v-row class="pl-5">
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Budget social" v-model="parameters.budget.social"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Budget communication" v-model="parameters.budget.communication"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Budget marketing" v-model="parameters.budget.marketing"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Budget maintenance" v-model="parameters.budget.maintenance"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Budget RSE et qualité" v-model="parameters.budget.quality"></v-switch>
                </v-col>
            </v-row>
            <v-divider class="my-5"></v-divider>
            <h3 class="pb-5 pt-5">4. Paramètres de prévisions</h3>
            <v-row class="pl-5">
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Chiffre d'affaires prévu" v-model="parameters.forecast.turnover"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Résultat prévu" v-model="parameters.forecast.result"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Trésorerie prévu" v-model="parameters.forecast.treasury"></v-switch>
                </v-col>
            </v-row>
            <v-divider class="my-5"></v-divider>
            <h3 class="pb-5 pt-5">5. Paramètres de machines</h3>
            <v-row class="pl-5">
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Achat de machine" v-model="parameters.machine.purchase"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Vente de machine" v-model="parameters.machine.sell"></v-switch>
                </v-col>
            </v-row>
            <v-divider class="my-5"></v-divider>
            <h3 class="pb-5 pt-5">6. Paramètres de production</h3>
            <v-row class="pl-5">
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Prix des produits" v-model="parameters.production.productPrice"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Prix des services" v-model="parameters.production.servicePrice"></v-switch>
                </v-col>
            </v-row>
            <v-divider class="my-5"></v-divider>
            <h3 class="pb-5 pt-5">7. Paramètres de ressources humaines</h3>
            <v-row class="pl-5">
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Salaires" v-model="parameters.hr.salary"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Formations" v-model="parameters.hr.training"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Embauche des vendeurs" v-model="parameters.hr.salesman.hiring"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Licenciement des vendeurs" v-model="parameters.hr.salesman.firing"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Commission des vendeurs" v-model="parameters.hr.salesman.commission"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Déploiement des vendeurs" v-model="parameters.hr.salesman.deployment"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Embauch des ouvriers" v-model="parameters.hr.worker.hiring"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Licenciement des ouvriers" v-model="parameters.hr.worker.firing"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Embauche des employés" v-model="parameters.hr.employee.hiring"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Licenciement des employés" v-model="parameters.hr.employee.firing"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Embauche des Managers" v-model="parameters.hr.serviceManager.hiring"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Licenciement des Managers" v-model="parameters.hr.serviceManager.firing"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Embauche des chefs de production" v-model="parameters.hr.productManager.hiring"></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-switch label="Licenciement des chefs de production" v-model="parameters.hr.productManager.firing"></v-switch>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "DecisionParametersTab",
    props: ["session"],
    data() {
        return {
            parameters : {...this.session.decisionParameters}
        }
    },
}
</script>