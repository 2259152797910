<template>
  <v-card v-if="session && parameters">
    <v-card-text>
      <h3 class="pb-5 pt-5">1. Paramètres globaux</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Indice conjoncturel"
            suffix="%"
            v-model="parameters.common.conjuncturalIndex"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Durée d'une période"
            suffix="Jour(s)"
            v-model="parameters.common.periodDuration"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">2. Paramètres financiers</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Capital social initial"
            suffix="€"
            v-model="parameters.financial.initialSocialCaptial"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Trésorerie initiale"
            suffix="€"
            v-model="parameters.financial.initialTreasury"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Coûts structurels généraux"
            suffix="€"
            v-model="parameters.financial.generalStructuralCost"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Taux d'intérêt des emprunts"
            suffix="%"
            v-model="parameters.financial.loanRate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Taux de pénalité des emprunts"
            suffix="%"
            v-model="parameters.financial.loanPenalityRate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Durée maximale des emprunts"
            suffix="Période(s)"
            v-model="parameters.financial.maxLoanDuration"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Taux de découvert"
            suffix="%"
            v-model="parameters.financial.overdraftRate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Taux des placements"
            suffix="%"
            v-model="parameters.financial.investmentRate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Taux des escomptes"
            suffix="%"
            v-model="parameters.financial.discountRate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Escomptes maximum"
            suffix="%"
            v-model="parameters.financial.maxDiscount"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Taux des impots"
            suffix="%"
            v-model="parameters.financial.taxRate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Durée maximale des credit d'impots"
            suffix="Période(s)"
            v-model="parameters.financial.maximumAmountPeriodsTaxCredit"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">3. Paramètres des études</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Prix de l'etude de marché"
            suffix="€"
            v-model="parameters.studies.marketStudyPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Prix de l'etude de ressources humaines"
            suffix="€"
            v-model="parameters.studies.hrStudyPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Prix de l'etude d'attractivité"
            suffix="€"
            v-model="parameters.studies.attractivityStudyPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Prix de l'etude RSE"
            suffix="€"
            v-model="parameters.studies.rseStudyPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Prix de l'etude de coûts"
            suffix="€"
            v-model="parameters.studies.costStudyPrice"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">4. Paramètres des production</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Production minimale"
            suffix="unité(s)"
            v-model="parameters.production.min"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Production maximale"
            suffix="unité(s)"
            v-model="parameters.production.max"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Delta de production maximale"
            suffix="%"
            v-model="parameters.production.delta"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">5. Paramètres RSE</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="RSE qualitée minimale"
            suffix="%"
            v-model="parameters.production.minRseQualityRate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="RSE qualitée maximale"
            suffix="%"
            v-model="parameters.production.maxRseQualityRate"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">6. Paramètres de maintenance</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Perte maximale de productivité"
            suffix="%"
            v-model="parameters.maintenance.maximalDecreaseRate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Echelle logarithmique"
            v-model="parameters.maintenance.isLog"
          ></v-switch>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">7. Paramètres des délais</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Délais clients"
            suffix="Jour(s)"
            v-model="parameters.deadline.customerDeadline"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Délais fournisseur"
            suffix="Jour(s)"
            v-model="parameters.deadline.supplierDeadline"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">8. Paramètres de score RH</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Coefficient du budget social"
            v-model="parameters.hrCoeff.socialBudget"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Coefficient de l'indice social"
            v-model="parameters.hrCoeff.socialIndex"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Coefficient de la commission"
            v-model="parameters.hrCoeff.commission"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Coefficient d'efficacité"
            v-model="parameters.hrCoeff.efficiency"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">9. Paramètres de score d'attractivité</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Coefficient du prix"
            v-model="parameters.scoreCoeff.price"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Coefficient de la qualité"
            v-model="parameters.scoreCoeff.quality"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Coefficient de la communication"
            v-model="parameters.scoreCoeff.communication"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Coefficient du marketing"
            v-model="parameters.scoreCoeff.marketing"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Coefficient de la force de vente"
            v-model="parameters.scoreCoeff.sellingPower"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">10. Paramètres d'inertie</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Taux d'inertie du prix"
            suffix="%"
            v-model="parameters.inertialRate.price"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Taux d'inertie de la RSE et qualité"
            suffix="%"
            v-model="parameters.inertialRate.quality"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Taux d'inertie de la communication"
            suffix="%"
            v-model="parameters.inertialRate.communication"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Taux d'inertie du marketing"
            suffix="%"
            v-model="parameters.inertialRate.marketing"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-text-field
            type="number"
            label="Taux d'inertie de la force de vente"
            suffix="%"
            v-model="parameters.inertialRate.sellingPower"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3>11. Parametre du marketplace</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Dépôt d'annonces"
            v-model="parameters.marketplace.posting"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Activer l'assurance"
            v-model="parameters.marketplace.showInsurance"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row class="pl-3">
        <v-col class="pt-5" cols="12" md="6" lg="4">
          <v-card outlined class="pa-5">
            <h4 class="text-center">Carton</h4>
            <v-text-field
              type="number"
              label="Quantité"
              v-model="parameters.marketplace.box.amount"
            ></v-text-field>
            <v-text-field
              type="number"
              label="Prix"
              suffix="€"
              v-model="parameters.marketplace.box.price"
            ></v-text-field>
            <v-text-field
              type="number"
              label="Distribution"
              suffix="%"
              v-model="parameters.marketplace.box.distribution"
            ></v-text-field>
            <span class="d-flex flex-row align-center justify-center">
              <v-switch
                label="Disponible"
                v-model="parameters.marketplace.box.enabled"
              ></v-switch>
            </span>
          </v-card>
        </v-col>
        <v-col class="pt-5" cols="12" md="6" lg="4">
          <v-card outlined class="pa-5">
            <h4 class="text-center">Palette</h4>
            <v-text-field
              type="number"
              label="Quantité"
              v-model="parameters.marketplace.pallet.amount"
            ></v-text-field>
            <v-text-field
              type="number"
              label="Prix"
              suffix="€"
              v-model="parameters.marketplace.pallet.price"
            ></v-text-field>
            <v-text-field
              type="number"
              label="Distribution"
              suffix="%"
              v-model="parameters.marketplace.pallet.distribution"
            ></v-text-field>
            <span class="d-flex flex-row align-center justify-center">
              <v-switch
                label="Disponible"
                v-model="parameters.marketplace.pallet.enabled"
              ></v-switch>
            </span>
          </v-card>
        </v-col>
        <v-col class="pt-5" cols="12" md="6" lg="4">
          <v-card outlined class="pa-5">
            <h4 class="text-center">Conteneur</h4>
            <v-text-field
              type="number"
              label="Quantité"
              v-model="parameters.marketplace.container.amount"
            ></v-text-field>
            <v-text-field
              type="number"
              label="Prix"
              suffix="€"
              v-model="parameters.marketplace.container.price"
            ></v-text-field>
            <v-text-field
              type="number"
              label="Distribution"
              suffix="%"
              v-model="parameters.marketplace.container.distribution"
            ></v-text-field>
            <span class="d-flex flex-row align-center justify-center">
              <v-switch
                label="Disponible"
                v-model="parameters.marketplace.container.enabled"
              ></v-switch>
            </span>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SessionConfigDialog",
  props: ["session"],
  data() {
    return {
      parameters: { ...this.session.mainParameters },
    };
  },
};
</script>
