import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "machines"

class Machine{
    constructor(id, name, icon, delivery, workplaceAmount, grossValue, structuralCost, optimalMaintenanceBudget, deprecationPeriods, saleLoss, productionBoost, rndCost, available, conso, carbonEmission){
        this.id = id
        this.name = name
        this.icon = icon
        this.delivery = delivery
        this.workplaceAmount = workplaceAmount
        this.grossValue = grossValue
        this.structuralCost = structuralCost
        this.optimalMaintenanceBudget = optimalMaintenanceBudget
        this.deprecationPeriods = deprecationPeriods
        this.saleLoss = saleLoss
        this.productionBoost = productionBoost
        this.rndCost = rndCost
        this.available = available
        this.conso = conso
        this.carbonEmission = carbonEmission
    }

    static async initializeOne(name){
        let tmp_machine = new Machine(null, name, 1, 0, 12, 200000, 30000, 7500, 20, 10, 0, 0, true, 100, 5, 50)
        return tmp_machine
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let machines = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_machine = new Machine(doument.id, data.name, data.icon, data.delivery, data.workplaceAmount, data.grossValue, data.structuralCost, data.optimalMaintenanceBudget, data.deprecationPeriods, data.saleLoss, data.productionBoost, data.rndCost, data.available, data.conso, data.carbonEmission)
            machines.push(tmp_machine)
        })

        return machines
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let machine = new Machine(response.id, data.name, data.icon, data.delivery, data.workplaceAmount, data.grossValue, data.structuralCost, data.optimalMaintenanceBudget, data.deprecationPeriods, data.saleLoss, data.productionBoost, data.rndCost, data.available, data.conso, data.carbonEmission)

        return machine
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let machines = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_machine = new Machine(document.id, data.name, data.icon, data.delivery, data.workplaceAmount, data.grossValue, data.structuralCost, data.optimalMaintenanceBudget, data.deprecationPeriods, data.saleLoss, data.productionBoost, data.rndCost, data.available, data.conso, data.carbonEmission)
                machines.push(tmp_machine)
            })
            
            if(callback != null){
                callback(machines)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let machine = new Machine(snapshot.id, data.name, data.icon, data.delivery, data.workplaceAmount, data.grossValue, data.structuralCost, data.optimalMaintenanceBudget, data.deprecationPeriods, data.saleLoss, data.productionBoost, data.rndCost, data.available, data.conso, data.carbonEmission)
            
            if(callback != null){
                callback(machine)
            }
        })
        return unsub
    }

    async rename(newName){
        this.name = newName
        await updateDoc(doc(db, collectionName, this.id), {
            name : newName,
        })
    }

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                name : this.name,
                icon : this.icon,
                delivery : this.delivery,
                workplaceAmount : this.workplaceAmount,
                grossValue : this.grossValue,
                structuralCost : this.structuralCost,
                optimalMaintenanceBudget : this.optimalMaintenanceBudget,
                deprecationPeriods : this.deprecationPeriods,
                saleLoss : this.saleLoss,
                productionBoost : this.productionBoost,
                rndCost : this.rndCost,
                available : this.available,
                conso : this.conso,
                carbonEmission : this.carbonEmission,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                name : this.name,
                icon : this.icon,
                delivery : this.delivery,
                workplaceAmount : this.workplaceAmount,
                grossValue : this.grossValue,
                structuralCost : this.structuralCost,
                optimalMaintenanceBudget : this.optimalMaintenanceBudget,
                deprecationPeriods : this.deprecationPeriods,
                saleLoss : this.saleLoss,
                productionBoost : this.productionBoost,
                rndCost : this.rndCost,
                available : this.available,
                conso : this.conso,
                carbonEmission : this.carbonEmission,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Machine