<template>
  <v-card v-if="session && parameters">
    <v-card-text>
      <h3 class="pb-5 pt-5">1. Afficher/cacher une page</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-switch label="Dashboard" v-model="parameters.dashboard"></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch label="Decision" v-model="parameters.decision"></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch label="Résultats" v-model="parameters.results"></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch label="Graphs" v-model="parameters.graphs"></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch label="Etudes" v-model="parameters.studies"></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Informations"
            v-model="parameters.informations"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch label="News" v-model="parameters.news"></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch label="Documents" v-model="parameters.documents"></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Challenges"
            v-model="parameters.challenges"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Marketplace"
            v-model="parameters.marketplace"
          ></v-switch>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">2. Afficher/cacher un graph</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Données financières"
            v-model="parameters.graphFinancial"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Rentabilité"
            v-model="parameters.graphRentability"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Situation nette"
            v-model="parameters.graphNetSituation"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Endettement"
            v-model="parameters.graphDebt"
          ></v-switch>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">3. Afficher/cacher un tableau de résultats</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-switch label="Bilan" v-model="parameters.bilan"></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Compte de resultat"
            v-model="parameters.resultAccount"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Compte de tresorerie"
            v-model="parameters.treasuryAccount"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Tableau de vente"
            v-model="parameters.salesTable"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Tableau de production"
            v-model="parameters.productionTable"
          ></v-switch>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <h3 class="pb-5 pt-5">4. Afficher/cacher une donnée</h3>
      <v-row class="pl-5">
        <v-col cols="12" md="6" lg="4">
          <v-switch
            label="Details des autres charges/produits"
            v-model="parameters.otherDetails"
          ></v-switch>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DisplayParametersTab",
  props: ["session"],
  data() {
    return {
      parameters: this.session.displayParameters,
    };
  },
};
</script>
