import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "services"

class Service{
    constructor(id, name, icon, purchaseShare, minimalPrice, maximalPrice, carryoverRate, productionTime, productionCost, distributionCost, rndCost){
        this.id = id
        this.name = name
        this.icon = icon
        this.purchaseShare = purchaseShare
        this.minimalPrice = minimalPrice
        this.maximalPrice = maximalPrice
        this.carryoverRate = carryoverRate
        this.productionTime = productionTime
        this.productionCost = productionCost
        this.distributionCost = distributionCost
        this.rndCost = rndCost
    }

    static async initializeOne(name){
        let tmp_service = new Service(null, name, 1, 10, 100, 300, 30, 3, 50, 3, 0)
        return tmp_service
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let services = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_service = new Service(doument.id, data.name, data.icon, data.purchaseShare, data.minimalPrice, data.maximalPrice, data.carryoverRate, data.productionTime, data.productionCost, data.distributionCost, data.rndCost)
            services.push(tmp_service)
        })

        return services
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let service = new Service(response.id, data.name, data.icon, data.purchaseShare, data.minimalPrice, data.maximalPrice, data.carryoverRate, data.productionTime, data.productionCost, data.distributionCost, data.rndCost)

        return service
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let services = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_service = new Service(document.id, data.name, data.icon, data.purchaseShare, data.minimalPrice, data.maximalPrice, data.carryoverRate, data.productionTime, data.productionCost, data.distributionCost, data.rndCost)
                services.push(tmp_service)
            })
            
            if(callback != null){
                callback(services)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let service = new Service(snapshot.id, data.name, data.icon, data.purchaseShare, data.minimalPrice, data.maximalPrice, data.carryoverRate, data.productionTime, data.productionCost, data.distributionCost, data.rndCost)
            
            if(callback != null){
                callback(service)
            }
        })
        return unsub
    }

    async rename(newName){
        this.name = newName
        await updateDoc(doc(db, collectionName, this.id), {
            name : newName,
        })
    }

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                name : this.name,
                icon : this.icon,
                purchaseShare : this.purchaseShare,
                minimalPrice : this.minimalPrice,
                maximalPrice : this.maximalPrice,
                carryoverRate : this.carryoverRate,
                productionTime : this.productionTime,
                productionCost : this.productionCost,
                distributionCost : this.distributionCost,
                rndCost : this.rndCost,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                name : this.name,
                icon : this.icon,
                purchaseShare : this.purchaseShare,
                minimalPrice : this.minimalPrice,
                maximalPrice : this.maximalPrice,
                carryoverRate : this.carryoverRate,
                productionTime : this.productionTime,
                productionCost : this.productionCost,
                distributionCost : this.distributionCost,
                rndCost : this.rndCost,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Service