import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "formations"

class Formation{
    constructor(id, name, job, cost, duration, salaryIncrease, staffCapacityIncrease, efficiencyIncrease){
        this.id = id
        this.name = name
        this.job = job
        this.cost = cost
        this.duration = duration
        this.salaryIncrease = salaryIncrease
        this.staffCapacityIncrease = staffCapacityIncrease
        this.efficiencyIncrease = efficiencyIncrease
    }

    static async initializeOne(name){
        let tmp_formation = new Formation(null, name, "Salesman", 10000, 10, 2, 0, 5)
        return tmp_formation
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let formations = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_formation = new Formation(doument.id, data.name, data.job, data.cost, data.duration, data.salaryIncrease, data.staffCapacityIncrease, data.efficiencyIncrease)
            formations.push(tmp_formation)
        })

        return formations
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let formation = new Formation(response.id, data.name, data.job, data.cost, data.duration, data.salaryIncrease, data.staffCapacityIncrease, data.efficiencyIncrease)

        return formation
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let formations = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_formation = new Formation(document.id, data.name, data.job, data.cost, data.duration, data.salaryIncrease, data.staffCapacityIncrease, data.efficiencyIncrease)
                formations.push(tmp_formation)
            })
            
            if(callback != null){
                callback(formations)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let formation = new Formation(snapshot.id, data.name, data.job, data.cost, data.duration, data.salaryIncrease, data.staffCapacityIncrease, data.efficiencyIncrease)
            
            if(callback != null){
                callback(formation)
            }
        })
        return unsub
    }

    async rename(newName){
        this.name = newName
        await updateDoc(doc(db, collectionName, this.id), {
            name : this.name,
        })
    }

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                name : this.name,
                job : this.job,
                cost : this.cost,
                duration : this.duration,
                salaryIncrease : this.salaryIncrease,
                staffCapacityIncrease : this.staffCapacityIncrease,
                efficiencyIncrease : this.efficiencyIncrease,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                name : this.name,
                job : this.job,
                cost : this.cost,
                duration : this.duration,
                salaryIncrease : this.salaryIncrease,
                staffCapacityIncrease : this.staffCapacityIncrease,
                efficiencyIncrease : this.efficiencyIncrease,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Formation