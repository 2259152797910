<template>
    <v-card v-if="session && parameters">
        <v-card-text>
            <v-expansion-panels focusable multiple>
                <v-expansion-panel v-for="product in Object.values(parameters).sort((a, b) => (a.name > b.name) ? 1 : -1)">
                    <v-expansion-panel-header>
                        <span class="d-flex align-center justify-space-between">
                            <span v-text="product.name"></span>
                            <span>
                                <v-btn color="secondary" icon>
                                    <v-icon @click.prevent.stop="openRenameDialog(product)">mdi-pencil-outline</v-icon>
                                </v-btn>
                                <v-btn color="primary" icon>
                                    <v-icon>mdi-cog-outline</v-icon>
                                </v-btn>
                                <v-btn color="error" icon>
                                    <v-icon @click.prevent.stop="deleteProduct(product)">mdi-delete-outline</v-icon>
                                </v-btn>
                            </span>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="pl-5">
                            <v-col cols="12" md="6" lg="3">
                                <v-select class="mb-5" :items="icons" item-value="index" v-model="product.icon" label="Icone">
                                    <template slot="item" slot-scope="data">
                                        <div class="py-5 d-flex align-center justify-center">
                                            <v-img contain height="150px" width="150px" :src="require(`@/assets/images/icons/products/${data.item.file}`)"></v-img>
                                        </div>
                                    </template>
                                    <template slot="selection" slot-scope="data">
                                        <div class="py-5 d-flex align-center justify-center">
                                            <v-img contain height="150px" width="150px" :src="require(`@/assets/images/icons/products/${data.item.file}`)"></v-img>
                                        </div>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6" lg="9">
                                <v-row>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Part d'achat" suffix="%" v-model="product.purchaseShare"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Prix min" suffix="€" v-model="product.minimalPrice"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Prix max" suffix="€" v-model="product.maximalPrice"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Taux de report" suffix="%" v-model="product.carryoverRate"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Temps de production" suffix="heure(s)" v-model="product.productionTime"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Coûts de production" suffix="€" v-model="product.productionCost"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Frais de distribution" suffix="%" v-model="product.distributionCost"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Frais de stockage" suffix="€" v-model="product.storageCost"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4">
                                        <v-text-field type="number" label="Coûts de R&D" suffix="%" v-model="product.rndCost"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="d-flex align-center justify-center">
                <v-col cols="8" class="pa-3 d-flex align-center justify-center">
                    <v-select :items="products" return-object item-text="name" v-model="selectedProduct" label="Produit"></v-select>
                    <v-btn color="primary" outlined class="ml-3" @click="addProduct">
                        <v-icon>mdi-plus</v-icon>
                        <b>Ajouter le produit</b>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="productRenameDialog" scrollable persistent max-width="500px">
            <v-card>
                <v-card-title class="headline">Renommer le produit</v-card-title>
                <v-card-text>
                    <v-text-field label="Nom de le produit" v-model="productRenameName"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="productRenameDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="renameProduct">Renommer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { v4 as uuidv4 } from 'uuid'

import assetsIcons from '@/config/assetsIcons';

import Product from "@/classes/Product";

export default {
    name: "ProductsParametersTab",
    props: ["session"],
    data() {
        return {
            unsub: [],
            parameters : this.session.productsConfig,
            products : [],
            selectedProduct : null,
            productRenameDialog : false,
            productRenameSelectedProduct : null,
            productRenameName : null,
            icons: assetsIcons.products
        }
    },
    created() {
        this.unsub.push(Product.listenAll((products) => {
            this.products = products
            this.products.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }))
    },
    methods: {
        addProduct() {
            if (this.selectedProduct) {
                let newProduct = {...this.selectedProduct}
                newProduct.uuid = uuidv4()
                this.parameters[newProduct.uuid] = newProduct
                this.selectedProduct = null
                this.$emit('shapeChange')
            }
        },
        openRenameDialog(product) {
            this.productRenameSelectedProduct = product
            this.productRenameName = product.name
            this.productRenameDialog = true
        },
        renameProduct() {
            this.productRenameSelectedProduct.name = this.productRenameName
            this.productRenameDialog = false
        },
        deleteProduct(product) {
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer ce produit ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    delete this.parameters[product.uuid]
                    this.$forceUpdate()
                this.$emit('shapeChange')
                }
            })
        }
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>