<template>
    <v-card v-if="session && parameters">
        <v-card-text>
            <v-expansion-panels focusable multiple>
                <v-expansion-panel v-for="zone in Object.values(parameters).sort((a, b) => (a.name > b.name) ? 1 : -1)">
                    <v-expansion-panel-header>
                        <span class="d-flex align-center justify-space-between">
                            <span v-text="zone.name"></span>
                            <span>
                                <v-btn color="secondary" icon>
                                    <v-icon @click.prevent.stop="openRenameDialog(zone)">mdi-pencil-outline</v-icon>
                                </v-btn>
                                <v-btn color="primary" icon>
                                    <v-icon>mdi-cog-outline</v-icon>
                                </v-btn>
                                <v-btn color="error" icon>
                                    <v-icon @click.prevent.stop="deleteZone(zone)">mdi-delete-outline</v-icon>
                                </v-btn>
                            </span>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="pl-5">
                            <v-col cols="12" md="6" lg="4">
                                <v-text-field type="number" label="Indice conjoncturel" suffix="%" v-model="zone.conjuncturalIndex"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="4">
                                <v-text-field type="number" label="Habitants par entreprises" suffix="Habitant(s)" v-model="zone.residentByCompany"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="4">
                                <v-text-field type="number" label="Pouvoir d'achat" suffix="€" v-model="zone.purchasingPower"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="4">
                                <v-text-field type="number" label="Coûts spécifiques" suffix="€" v-model="zone.specificCosts"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="4">
                                <v-text-field type="number" label="Surcoût de distribution" suffix="%" v-model="zone.overcost"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="pl-5">
                          <v-col cols="12">
                            <h3>Transport :</h3>
                          </v-col>
                          <v-col cols="12" md="6">
                              <v-switch label="Camion" v-model="zone.transports.car.available"></v-switch>
                              <span v-if="zone.transports.car.available">
                                <v-text-field type="number" label="Coût" suffix="%" v-model="zone.transports.car.cost"></v-text-field>
                                <v-text-field type="number" label="Emission de CO2" suffix="g/colis" v-model="zone.transports.car.carbonEmission"></v-text-field>
                              </span>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="12" md="6">
                              <v-switch label="Train" v-model="zone.transports.train.available"></v-switch>
                              <span v-if="zone.transports.train.available">
                                <v-text-field type="number" label="Coût" suffix="%" v-model="zone.transports.train.cost"></v-text-field>
                                <v-text-field type="number" label="Emission de CO2" suffix="g/colis" v-model="zone.transports.train.carbonEmission"></v-text-field>
                              </span>
                          </v-col>
                          <v-col cols="12">
                            <v-divider></v-divider>
                          </v-col>
                          <v-col cols="12" md="6">
                              <v-switch label="Bateau" v-model="zone.transports.boat.available"></v-switch>
                              <span v-if="zone.transports.boat.available">
                                <v-text-field type="number" label="Coût" suffix="%" v-model="zone.transports.boat.cost"></v-text-field>
                                <v-text-field type="number" label="Emission de CO2" suffix="g/colis" v-model="zone.transports.boat.carbonEmission"></v-text-field>
                              </span>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="12" md="6">
                              <v-switch label="Avion" v-model="zone.transports.plane.available"></v-switch>
                              <span v-if="zone.transports.plane.available">
                                <v-text-field type="number" label="Coût" suffix="%" v-model="zone.transports.plane.cost"></v-text-field>
                                <v-text-field type="number" label="Emission de CO2" suffix="g/colis" v-model="zone.transports.plane.carbonEmission"></v-text-field>
                              </span>
                          </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="d-flex align-center justify-center">
                <v-col cols="8" class="pa-3 d-flex align-center justify-center">
                    <v-select :items="zones" return-object item-text="name" v-model="selectedZone" label="Zone"></v-select>
                    <v-btn color="primary" outlined class="ml-3" @click="addZone">
                        <v-icon>mdi-plus</v-icon>
                        <b>Ajouter la zone</b>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="zoneRenameDialog" scrollable persistent max-width="500px">
            <v-card>
                <v-card-title class="headline">Renommer la zone</v-card-title>
                <v-card-text>
                    <v-text-field label="Nom de la zone" v-model="zoneRenameName"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="zoneRenameDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="renameZone">Renommer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { v4 as uuidv4 } from 'uuid'

import Zone from "@/classes/Zone";

export default {
    name: "ZonesParametersTab",
    props: ["session"],
    data() {
        return {
            unsub: [],
            parameters : this.session.zonesConfig,
            zones : [],
            selectedZone : null,
            zoneRenameDialog : false,
            zoneRenameSelectedZone : null,
            zoneRenameName : null
        }
    },
    created() {
        this.unsub.push(Zone.listenAll((zones) => {
            this.zones = zones
            this.zones.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }))
    },
    methods: {
        addZone() {
            if (this.selectedZone) {
                let newZone = {...this.selectedZone}
                newZone.uuid = uuidv4()
                this.parameters[newZone.uuid] = newZone
                this.selectedZone = null
            }
        },
        openRenameDialog(zone) {
            this.zoneRenameSelectedZone = zone
            this.zoneRenameName = zone.name
            this.zoneRenameDialog = true
        },
        renameZone() {
            this.zoneRenameSelectedZone.name = this.zoneRenameName
            this.zoneRenameDialog = false
        },
        deleteZone(zone) {
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer cette zone ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    delete this.parameters[zone.uuid]
                    this.$forceUpdate()
                }
            })
        }
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>