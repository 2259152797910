<template>
    <v-card v-if="session && parameters">
        <v-card-text>
            <v-expansion-panels focusable multiple accordion>
                <v-expansion-panel v-for="machine in  Object.values(machines).sort((a, b) => (a.name > b.name) ? 1 : -1)" v-if="machines && parameters[machine.uuid]">
                    <v-expansion-panel-header>
                        <span class="d-flex align-center justify-space-between">
                            <span v-text="machine.name"></span>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="pl-5">
                            <v-col cols="12" sm="6" v-if="parameters[machine.uuid].products">
                                <h4 class="ma-0">Produits :</h4>
                                <v-switch v-for="product in Object.values(products).sort((a, b) => (a.name > b.name) ? 1 : -1)" :label="product.name" v-model="parameters[machine.uuid].products[product.uuid]" @click="cancelServices(machine)"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="parameters[machine.uuid].services">
                                <h4 class="ma-0">Services :</h4>
                                <v-switch v-for="service in Object.values(services).sort((a, b) => (a.name > b.name) ? 1 : -1)" :label="service.name" v-model="parameters[machine.uuid].services[service.uuid]" @click="cancelProducts(machine)"></v-switch>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "ProductionParametersTab",
    props: ["session"],
    data() {
        return {
            unsub: [],
            parameters : this.session.productionParameters,
            machines : this.session.machinesConfig,
            products : this.session.productsConfig,
            services : this.session.servicesConfig,
        }
    },
    methods: {
        reloadData() {
            this.parameters = this.session.productionParameters
            this.machines = this.session.machinesConfig
            this.products = this.session.productsConfig
            this.services = this.session.servicesConfig
        },
        cancelServices(machine){
            for(let serviceId in this.parameters[machine.uuid].services){
                this.parameters[machine.uuid].services[serviceId] = false
            }
        },
        cancelProducts(machine){
            for(let productId in this.parameters[machine.uuid].products){
                this.parameters[machine.uuid].products[productId] = false
            }
        },
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>