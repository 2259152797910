let assetsIcons = {
  machines : [
    {
        text: 'Machine 1',
        file: 'Machine1.svg',
        index: 1
    },
    {
        text: 'Machine 2',
        file: 'Machine2.svg',
        index: 2
    },
    {
        text: 'Machine 3',
        file: 'Machine3.svg',
        index: 3
    },
    {
        text: 'Machine 4',
        file: 'Machine4.svg',
        index: 4
    },
    {
        text: 'Machine 5',
        file: 'Machine5.svg',
        index: 5
    },
    {
        text: 'Machine 6',
        file: 'Machine6.svg',
        index: 6
    },
    {
        text: 'Machine 7',
        file: 'Machine7.svg',
        index: 7
    },
    {
        text: 'Machine 8',
        file: 'Machine8.svg',
        index: 8
    },
  ],
  products : [
    {
      text: 'Product 1',
      file: 'Product1.svg',
      index: 1
    },
    {
        text: 'Product 2',
        file: 'Product2.svg',
        index: 2
    },
    {
        text: 'Product 3',
        file: 'Product3.svg',
        index: 3
    },
    {
        text: 'Product 4',
        file: 'Product4.svg',
        index: 4
    },
    {
        text: 'Product 5',
        file: 'Product5.svg',
        index: 5
    },
  ],
  services : [
    {
      text: 'Service 1',
      file: 'Service1.svg',
      index: 1
    },
    {
        text: 'Service 2',
        file: 'Service2.svg',
        index: 2
    },
    {
        text: 'Service 3',
        file: 'Service3.svg',
        index: 3
    },
    {
        text: 'Service 4',
        file: 'Service4.svg',
        index: 4
    },
    {
        text: 'Service 5',
        file: 'Service5.svg',
        index: 5
    },
  ],
}

export default assetsIcons