<template>
  <span v-if="profile.role != 'User'">
    <v-card class="mt-3 pa-3">
      <v-card-title primary-title class="justify-center">
        <h1 class="text-center mb-3">Sessions</h1>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-data-table :headers="sessionsHeaders" :items="sessionsItems">
              <template v-slot:item.actions="{ item }">
                <v-btn color="secondary" icon>
                  <v-icon @click="openRenameDialog(item)"
                    >mdi-pencil-outline</v-icon
                  >
                </v-btn>
                <v-btn color="primary" icon>
                  <v-icon @click="openConfigDialog(item)"
                    >mdi-cog-outline</v-icon
                  >
                </v-btn>
                <v-btn color="accent" icon>
                  <v-icon @click="saveAsBase(item)"
                    >mdi-database-outline</v-icon
                  >
                </v-btn>
                <v-btn color="error" icon>
                  <v-icon @click="deleteSession(item)"
                    >mdi-delete-outline</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="6">
            <v-data-table :headers="basesHeaders" :items="basesItems">
              <template v-slot:item.actions="{ item }">
                <v-btn color="secondary" icon>
                  <v-icon @click="openRenameDialog(item)"
                    >mdi-pencil-outline</v-icon
                  >
                </v-btn>
                <v-btn color="primary" icon>
                  <v-icon @click="openConfigDialog(item)"
                    >mdi-cog-outline</v-icon
                  >
                </v-btn>
                <v-btn color="error" icon>
                  <v-icon @click="deleteSession(item)"
                    >mdi-delete-outline</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-col
          cols="12"
          class="pa-3 d-flex justify-end"
          v-if="
            new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(
              profile.role
            )
          "
        >
          <v-btn
            color="primary"
            fab
            class="ml-3"
            @click="sessionCreationDialog = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="sessionRenameDialog"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">Renommer la session</v-card-title>
        <v-card-text>
          <v-text-field
            label="Nom de la session"
            v-model="sessionRenameName"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="sessionRenameDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="renameSession">Renommer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="sessionCreationDialog"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">Ajouter une session</v-card-title>
        <v-card-text>
          <v-text-field
            label="Nom de la session"
            v-model="sessionCreationName"
          ></v-text-field>
          <v-switch
            label="Charger une base"
            v-model="sessionCreationLoadBase"
          ></v-switch>
          <v-select
            label="Selectionner la base"
            :items="basesItems"
            item-text="name"
            return-object
            v-if="sessionCreationLoadBase"
            v-model="sessionCreationBase"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="sessionCreationDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="createSession">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SessionConfigDialog ref="sessionConfigDialog" />
  </span>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

import Session from "@/classes/Session";

import SessionConfigDialog from "@/components/sessions/SessionConfigDialog";

export default {
  name: "Sessions",
  props: ["user", "profile"],
  components: {
    SessionConfigDialog,
  },
  data() {
    return {
      unsub: [],
      sessions: [],

      basesHeaders: [
        { text: "Bases", value: "name" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],
      sessionsHeaders: [
        { text: "Sessions", value: "name" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],

      sessionCreationDialog: false,
      sessionCreationName: "",
      sessionCreationLoadBase: false,
      sessionCreationBase: null,

      sessionRenameDialog: false,
      sessionRenameSelectedSession: null,
      sessionRenameName: "",
    };
  },
  computed: {
    basesItems() {
      return this.sessions.filter((session) => session.isBase);
    },
    sessionsItems() {
      return this.sessions.filter((session) => !session.isBase);
    },
  },
  created() {
    this.unsub.push(
      Session.listenAll((sessions) => {
        sessions.sort((a, b) => b.date - a.date);
        this.sessions = sessions;
      })
    );
  },
  methods: {
    createSession() {
      if (this.sessionCreationName.trim() != "") {
        if (!this.sessionCreationLoadBase) {
          Session.initializeOne(this.sessionCreationName).then((session) => {
            session.save();
            this.sessionCreationDialog = false;
          });
        } else if (this.sessionCreationBase != null) {
          this.sessionCreationBase
            .duplicate(this.sessionCreationName)
            .then(() => {
              this.sessionCreationDialog = false;
            });
        }
      }
    },
    openRenameDialog(session) {
      this.sessionRenameSelectedSession = session;
      this.sessionRenameName = session.name;
      this.sessionRenameDialog = true;
    },
    openConfigDialog(session) {
      this.$refs.sessionConfigDialog.openDialog(session);
    },
    renameSession() {
      this.sessionRenameSelectedSession.name = this.sessionRenameName;
      this.sessionRenameSelectedSession.save();
      this.sessionRenameDialog = false;
    },
    saveAsBase(session) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "Voulez-vous vraiment sauvegarder une copie des paramètres de cette session dans les bases ?",
        icon: "question",
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let base = new Session(
            null,
            session.name + " (Base)",
            true,
            session.machinesConfig,
            session.productsConfig,
            session.servicesConfig,
            session.zonesConfig,
            session.cvConfig,
            session.formationsConfig,
            session.mainParameters,
            session.hrParameters,
            session.decisionParameters,
            session.displayParameters,
            session.universesParameters,
            session.productionParameters,
            session.date
          );
          base.save();
        }
      });
    },
    deleteSession(session) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "Voulez vous vraiment supprimer cette session ?",
        icon: "question",
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          session.delete();
        }
      });
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
